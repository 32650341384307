import { useEffect, useState, useContext } from "react";
import API from "../../../../config/API";
import { Link, useParams, useNavigate } from 'react-router-dom';
import Loading from '../../../theme/loading';
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import randomColor from "randomcolor";
import {EntornoGlobal} from '../../../../config/globalContext';
import DataTable from "react-data-table-component";


const AssetEdit = (props) => {

  const noThumbnail = ["templates"];

  const [state, setState] = useState({ status: "loading", refresh: (+new Date()), disabled_button: false, versions: [], sizes: [] });
  const [image, setImage] = useState({ image: false });
  const { id } = useParams();
  const nav = useNavigate();
  const [statecnx, setStateCnx] = useContext(EntornoGlobal);

  const prop_resource = props.resource;
  const prop_resourceName = props.name;

  useEffect(() => {
    const fn = async () => {

    // Recover information
    const resource = await API.get('/v3/api/'+prop_resource+'/' + id);
    if(typeof resource === "string"){return setState({status: "error", error: resource})};

    const owner = (resource.user===statecnx.user.id || statecnx.user.isAdmin);

    const error = await API.get('/v3/api/'+prop_resource+'/' + id + '/errors', {}, true);

    let resourceThumbnail = null;

    if( noThumbnail.includes(prop_resource) === false ){
      resourceThumbnail = await API.get('/v3/api/'+prop_resource+'/' + id + '/thumbnail', {}, "blob");

      // Parse Thembnail
      if (resourceThumbnail?.type !== "application/json") {
        const img = URL.createObjectURL(resourceThumbnail);
        setImage({ image: img });
      }

    }

    // Tag
      const tags = (Array.isArray(resource?.data?.components?.TagsComponent?.value)?
      resource.data.components.TagsComponent.value : []);

    // Attributes
      const attributes = [];

    // ObjectID
      const objectId = resource?.data?.components?.ObjectIdComponent?.value

      try {
        for (const key in resource?.data.components.AttributesComponent.value) {
          attributes.push({key, value: resource?.data.components.AttributesComponent.value[key].value})
        }
      } catch (e) {}
      

    if(owner){
      const resourceVersions = await API.get('/v3/api/'+prop_resource+'/' + id + "/versions");
      const fileSizes = await API.get('/v3/api/'+prop_resource+'/' + id + "/files/sizes");

      return setState({
        ...state,
        data: resource,
        status: "loaded",
        disabled_button: false,
        versions: resourceVersions.data,
        error,
        owner,
        tags,
        attributes,
        objectId,
        sizes: fileSizes.sort((a, b) => { return b.size - a.size }).map(e => ({ ...e, value: +e.size / 1000000, color: randomColor() }))
      });
    }

    // not owner
      setState({ ...state, data: resource, status: "loaded", disabled_button: false, owner, attributes, objectId, tags});

    };
    fn();

  }, [id, state.refresh]);


  const deleteAsset = async (idAsset, version) => {

    if (window.confirm(`Delete ${version} version?`)) {
      await API.delete(`/v3/api/${prop_resource}/${idAsset}/?fullDelete=true`);

      if (idAsset === id) {
        nav("/app/"+prop_resource);
      } else {
        setState({ ...state, refresh: (+new Date()) });
      }

    }
  }

  const canDelete = (e) => {
    if( statecnx.user.isAdmin ){return true; }

    const isUnique = state.versions.length === 1;
    const isLast = (state.versions.findIndex(v=>v.id===e.id)+1) === state.versions.length;

    if((isLast || isUnique) && e.state != 1){ return true; }

    return false;
  }

  const canRetry = (elem) => {
    const isLast = (state.versions.findIndex(v=>v.id===elem.id)+1) === state.versions.length;
    
    if( statecnx.user.isAdmin && isLast && elem.state != 1){ 
      return true; 
    }

    return false;
  }

  const retry = async (idAsset) => {
    if( window.confirm("Are you sure you want to retry?") ){
      await API.put("/v3/api/"+prop_resource+"/"+idAsset+"/retryProcess")
      nav("/app/pipe");
    }
  }

  const prepareListFiles = ()=> {

    let sizes = [...state.sizes];

    sizes.push({
      name: "Total render",
      usage: "---",
      value: sizes.filter(e => e.usage === "render").reduce((acum, e) => { return acum + e.value; }, 0)
    });


    sizes.push({
      name: "Total",
      usage: "---",
      value: sizes.reduce((acum, e) => { return acum + e.value; }, 0)
    });

    return sizes;
  }

  const columnsVersions = [
    { name: 'ID', selector: (e) => <Link to={"/app/"+prop_resource+"/get/" + e.id} className="greenanyverse">{e.id}</Link> },
    { name: 'Created', selector: (e) => e.creation.slice(0,19).replace("T", " ")},
    { name: 'Updated', selector: (e) => e.lastUpdated.slice(0,19).replace("T", " ")},
    { name: 'Versión', selector: e => e.version},
    { name: 'State', selector: e => e.state},
    { name: 'Enabled', selector: e => (e.enabled?"True":"False")},
    { name: 'Latest', selector: e => (e.latest?"True":"False")},
    //{ name: '', selector: e => statecnx.user.isAdmin && <button className="btn btn-danger" onClick={() => { deleteAsset(e.id, e.version) }}>Delete</button> },
    { name: '', selector: e => canRetry(e) && <button className="btn btn-danger" onClick={() => { retry(e.id) }}>Retry</button> },
    { name: '', selector: e => canDelete(e) && <button className="btn btn-danger" onClick={() => { deleteAsset(e.id, e.version) }}>Delete</button> },
  ];

  const columnFiles = [
    { name: 'Name', selector: e => e.name},
    { name: 'Usage', selector: e => e.usage},
    { name: 'Size', selector: e => e.value+" MB"}
  ];



  if (state.status === "error") { return <div className="m-5 mt-2"> <div className="alert alert-danger">{state.error}</div> </div>; }
  if (state.status !== "loaded") { return <div className="m-5 mt-2"><Loading /></div>; }

  return (<div>
    <h2>{prop_resourceName}: {state.data.name}</h2>
    <div className="grey">ID {id}</div>
    <br />

    <div className="row">
      <div className="col-md-6">

        {
          (state.alertsuccess && <div className="alert alert-success">{state.alertsuccess}</div>)
        }


        <div className="row">
          <div className="col-md-2 fw-bold">Name</div>
          <div className="col-md-10">{prop_resourceName}</div>
        </div>

        {state.owner && <div className="row mt-5">
          <div className="col-md-2 fw-bold">Owner</div>
          <div className="col-md-10">{state.data.user}</div>
        </div>}

        {state.owner && <div className="row mt-5">
          <div className="col-md-2 fw-bold">Groups</div>
          <div className="col-md-10">
            
            { Array.isArray(state.data.data.components.ResourceComponent.groups) ? 
            state.data.data.components.ResourceComponent.groups.map(e=>
              <button className="btn btn-anyverse-secondary p-0 ps-4 pe-4 me-3">{e}</button>
              ) :""}
            
            </div>
        </div>}

        {<div className="row mt-5">
          <div className="col-md-2 fw-bold">Object ID</div>
          <div className="col-md-10">{state.objectId}</div>
        </div>}

        {<div className="row mt-5">
          <div className="col-md-2 fw-bold">Tags</div>
          <div className="col-md-10">
            
            { Array.isArray(state.tags) ? 
            state.tags.map(e=>
              <button className="btn btn-anyverse-secondary p-0 ps-4 pe-4 me-3">{e}</button>
              ) :""}
            
            </div>
        </div>}


        {<div className="row mt-5">
          <div className="col-md-2 fw-bold">Attributes</div>
          <div className="col-md-10">
            
            { Array.isArray(state.attributes) ? 
            state.attributes.map(e=>
              <button className="btn btn-anyverse-secondary p-0 ps-4 pe-4 me-3">{e.key}: {(typeof e.value === "boolean"?e.value.toString():e.value)}</button>
              ) :""}
            
            </div>
        </div>}

      </div>
      <div className="col-md-6">

        {
          (image.image !== false &&
            <div>
              <div className="m-5 mt-1"><img src={image.image} className="img-fluid" /></div>
            </div>)
        }


        {
          state.owner && 
          state?.data?.data?.components?.ResourceRenderStatsComponent &&
          <div>
            <h2>Render Performance</h2>
            <div className="m-2 mb-5">
              {<table className="table greenanyverse">
                <tr key="inicial"> <td>Type</td> <td>Value</td>  </tr>
                { Object.keys(state.data.data.components.ResourceRenderStatsComponent).sort().map((e) => {
                  return <tr key={e}>
                    <td>{e}</td>
                    <td>{ JSON.stringify(state.data.data.components.ResourceRenderStatsComponent[e])}</td>
                  </tr>;
                })}
              </table>}
          </div>
          </div>
        }

        {
          state.owner && 
          state?.error &&
          <div>
            <h2>Error</h2>
            {
              state.error.split('\n').slice(-50).map((e,i) => <div key={i}>{e}</div>)
            }
          </div>
        }
        {
          state.owner && 
          state?.aditionalInfo &&
          <div>
            <h2>Additional Info</h2>
            {
              state?.aditionalInfo
            }
          </div>
        }

      </div>
    </div>

    <hr/>

    {
      state.owner && 
        <div>
          <div className="h5 grey mt-4 mb-4">Versions</div>

          <DataTable
                columns={columnsVersions}
                data={state.versions}
            />
        </div>
      }


    {state.owner &&

<>
  <div className="h5 grey mt-5 mb-4">File sizes</div>
  <div className="row">
    <div className="col-md-6">

          <div className="m-2 mb-5">

            <DataTable
              columns={columnFiles}
              data={prepareListFiles()}
            />

          </div>

    </div>
    <div className="col-md-6">

      <div className="m-2 mb-5">
        <PieChart width={400} height={400}>
          <Pie
            nameKey="name"
            dataKey="value"
            isAnimationActive={false}
            data={state.sizes}
            innerRadius="50%"
            outerRadius="100%"
          >
            {state.sizes.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>

    </div>
  </div>


        </>}







  </div>);

}

export default AssetEdit;