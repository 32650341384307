import { Fragment, useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import {Link} from 'react-router-dom';
import API from '../../../config/API';
import {EntornoGlobal} from '../../../config/globalContext';
import {arrayToCsv, downloadCSV} from '../../../helpers/arraytoCSV';
import Loading from '../../theme/loading';


const ListOntology = () => {

  const [state, setState] = useState({ status : "loading", ontologies: [], search: "" })
  const [statecnx, setStateCnx] = useContext(EntornoGlobal);

  useEffect(()=>{
    API.get('/v3/api/ontology', {}).then((data)=>{
      if(Array.isArray(data)){

        const parse_data = data.map( (e) => {
            
            const name = Object.keys(e)[0];

            const attributes = [];

            if(e[name]?.instance===true){ attributes.push("Instance")}
            if(e[name]?.roadLayer===true){ attributes.push("Road Layer")}

            return {
                name: name,
                r: e[name].r,
                g: e[name].g,
                b: e[name].b,
                attributes: attributes.join(", "),
                instance: (e[name]?.instance===true?"yes":"no"),
                road_layer: (e[name]?.roadLayer===true?"yes":"no"),
                rgb: e[name].r+", "+e[name].g+", "+e[name].b
            }

        })

        setState({status:"loaded", ontologies:parse_data, search:""});
      }else{
        setState({status:"error", message: "You don't have permissions"});
      }
    });
  },[]);

  const filtersOntology = () => {

    return state.ontologies.filter((e)=>{
      
      const row = (e.name+e.attributes).toLowerCase();

      return (row.indexOf(state.search.toLowerCase()) !== -1?true:false);

    });

  }

  const download = () => {

    // Parse ontology
      let parse = state.ontologies.map( row =>
        [`"${row.name}"`, `"${row.rgb}"`, row.instance, row.road_layer]
      )

    // Add header
      parse = [["name", "RGB", "Instance", "Road Layer"]].concat(parse);

    let csv = arrayToCsv(parse);

    const name = "ontology-"+new Date().toISOString().split('T')[0]+".csv";

    downloadCSV(csv, name, 'text/csv;charset=utf-8;')
    
  }

  const columns = [
    { name: 'Name', selector: row => (<Link to={"/app/ontology/edit/"+row.name}>{row.name}</Link>), sortable: true },
    { name: 'Color', selector: row => (<Link to={"/app/ontology/edit/"+row.name}>{"rgb("+row.r+", "+row.g+", "+row.b+")"}</Link>), },
    { name: 'Color', selector: row => (<Link to={"/app/ontology/edit/"+row.name} style={{color: "rgb("+row.r+", "+row.g+", "+row.b+")"}}><div>████████████</div></Link>), },
    { name: 'Attributes', selector: row => (<Link to={"/app/ontology/edit/"+row.name}><div>{(row.attributes)}</div></Link>), }
  ];

  if(statecnx?.user?.canEditOntology){ columns.push({ name: 'Actions', selector: (row) => (<Link to={"/app/ontology/edit/"+row.name}>Edit registry</Link>), }); }

  if(state.status === "loading"){ return <Loading /> }

  return(
    <div>
      <div className="row">
        <div className="col-md-6">
          <h2>Object Ontology</h2>
        </div>
        <div className="col-md-6 text-end">
          {(statecnx?.user?.canEditOntology&&
            <Link to="/app/ontology/add"><button className="btn btn-success">Add registry</button></Link>
          )}
        </div>
      </div>
      
      <div className="row mt-3 mb-3">
        <div className="col-md-12">
          <input type="text" className="form-control" onChange={(e)=>{ setState({...state, search: e.target.value})}} placeholder="Search..." />
        </div>
      </div>

      {(state.status==='error'&&<div className="alert alert-danger">{state.message}</div>)}

      {(state.status==='loaded'&&<DataTable
            columns={columns}
            data={filtersOntology(state.ontology)}
        />)}

        <div className="text-end">
          <button className="btn btn-info mt-3 mb-3" onClick={download}>Download ontology CSV</button>
        </div>


    </div>
  )
};

export default ListOntology;