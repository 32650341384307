import ResourceGet from '../../resource/get';

const AssetsGet = () => {

      return(
        <div>
          <ResourceGet resource="animations" name="Animations" />
        </div>
      )

}

export default AssetsGet;