import ResourceGet from '../../resource/get';

const AssetsGet = () => {

      return(
        <div>
          <ResourceGet resource="materials" name="Materials" />
        </div>
      )

}

export default AssetsGet;