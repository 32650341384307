import { useState, useEffect  } from "react";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const options = [ "empty", "man", "woman", "person", "child", "object", "animal" ];

const Seat = ({onUpdate, seatNumber}) => {

  const [seatContent, setSeatContent] = useState(options[0]);
  const [seatBelt, setSeatBelt] = useState(false);
  const [init, setInit] = useState(false);

  useEffect(() => {
    if(!init) {
      onUpdate(recalculateContent(), seatNumber);
      setInit(true);
    }
  }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour


  useEffect(() => {
    onUpdate(recalculateContent(), seatNumber);
  }, [seatContent, seatBelt]);

  const recalculateContent = () => {
    return {
      seat: "seat" + seatNumber,
      occupant: seatContent,
      child_seat: seatContent === "child",
      seat_belt_on: seatBelt
    };
  }

  const onSelectChanged = e => {
    setSeatContent(e.target.value);
  }

  const onSeatbeltChanged = e => {
    setSeatBelt(!seatBelt);
  }

  return(
    <Form>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>Content</Form.Label>
        <Form.Select onChange={onSelectChanged}>
          { options.map(option => <option> {option} </option> )}
        </Form.Select>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Check column type="switch"
          onChange={onSeatbeltChanged}
          id={seatNumber+"switch"}
          label="Seatbelt"/>
      </Form.Group>
    </Form>
  )
};

export default Seat;