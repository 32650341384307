import { Fragment, useState } from "react";
import API from '../../../config/API';
import {useNavigate} from 'react-router-dom';

const AddOntology = () => {
  const [state, setState] = useState({disabled:true, name:"", R:"", G:"", B:"", instance:false, roadLayer:false});
  const nav = useNavigate();

  const createOntology = () =>{

    if(validOntology()){
      setState({...state, disabled:true});
      API.post('/v3/api/ontology/', {
        name: state.name,
        r: parseInt(state.R),
        g: parseInt(state.G),
        b: parseInt(state.B),
        instance: state.instance,
        roadLayer: state.roadLayer
      }).then( (e)=>{

        if(typeof e !== 'string'){
          nav('/app/ontology');
        }else{
          setState({...state, disabled:false, alert:"danger", message: e});
        }

      } )

    }

  }

  const validOntology = () => {
    return (state.name.length>=1 && validRGB()?true:false);
  }

  const validRGB = () =>{
    const R = parseInt(state.R);
    const G = parseInt(state.G);
    const B = parseInt(state.B);

    return (R <= 255 && G <= 255 && B <= 255);
  }

  return(
    <Fragment>
      <h2>Add registry</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <div className="row">
        <div className="col-md-6">
          <h4>Name:</h4>
          <input type="text" id="name" className="form-control" onChange={(e)=>{setState({...state, name: e.target.value})}}/>

          <h4 className="mt-5">Color:</h4>
          <div className="row">
            <div className="col-md-4"><input type="number" className="form-control" placeholder={"R"} onChange={(e)=>{setState({...state, R: e.target.value})}}/></div>
            <div className="col-md-4"><input type="number" className="form-control" placeholder={"G"} onChange={(e)=>{setState({...state, G: e.target.value})}}/></div>
            <div className="col-md-4"><input type="number" className="form-control" placeholder={"B"} onChange={(e)=>{setState({...state, B: e.target.value})}}/></div>
          </div>
          {(validRGB()?<div className="mt-2 border border-white" style={{background:"rgb("+state.R+","+state.G+","+state.B+")", color:"rgb("+state.R+","+state.G+","+state.B+")"}}>█</div>:"")}

          <h4 className="mt-5">Instance:</h4>
          <select className="form-control" onChange={(e)=>{setState({...state, instance:(e.target.value==="true"?true:false)})}}>
            <option value="false">False</option>
            <option value="true">True</option>
          </select>

          <h4 className="mt-5">Road Layer:</h4>
          <select className="form-control" onChange={(e)=>{setState({...state, roadLayer:(e.target.value==="true"?true:false)})}}>
            <option value="false">False</option>
            <option value="true">True</option>
          </select>


          <button className="btn btn-success mt-5" onClick={createOntology} disabled={(!validOntology())}>Create registry</button>
        </div>


      </div>
    </Fragment>
  )
};

export default AddOntology;