
function arrayToCsv(data, forceString=false){
    return data.map((r) =>{
        let futurecsv = r.map(String);  // convert every value to String

        if(forceString){
            futurecsv = futurecsv
            .map(v => v.replaceAll('"', '""'))  // escape double colons
            .map(v => `"${v}"`)  // quote it
        }
        
        return futurecsv.join(',')  // comma-separated
    }).join('\r\n');  // rows starting on new lines
}

function downloadCSV(content, filename, contentType) {
    // Create a blob
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);
    
    // Create a link to download it
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
}

export { arrayToCsv, downloadCSV }