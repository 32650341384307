import ResourceList from '../../resource/list';

const AssetsList = () => {

      return(
        <div>
          <ResourceList resource="templates" name="Templates" />
        </div>
      )

}

export default AssetsList;