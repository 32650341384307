import ResourceGet from '../../resource/get';

const CharactersGet = () => {

      return(
        <div>
          <ResourceGet resource="characterAssets" name="Characters" />
        </div>
      )

}

export default CharactersGet;