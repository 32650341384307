import {Routes, Route, Router, Navigate} from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';

import Head from '../../components/theme/header';
import Body from '../../components/theme/body';
import Footer from '../../components/theme/footer';
import API from '../../config/API';
import {useNavigate} from 'react-router-dom';
import GlobalContext from '../../config/globalContext';
import {useLocation} from 'react-router-dom';
import ReactGA from "react-ga4";

const APP = (props) =>{

  const [state, setState] = useState({status:'loading'});
  const navigate = useNavigate()
  const {pathname} = useLocation();

//  ReactGA.send({ hitType: "pageview", page: pathname, title: pathname });

  useEffect(()=>{
    // Check if has token
      if(!API.get_token()){ navigate('/'); }

    // Check if the token is valid
      API.get('/v3/api/auth/me',{}).then((resp)=>{

        if(typeof resp === "string"){
          navigate('/');
        }else{
          setState({status:true, type: resp.isAdmin, datauser: resp});
        }
      });

  },[]);

  if(state.status !== true){ return(<div></div>); }

	return ( 
    <Fragment>
      <GlobalContext data={state.datauser}>
          <Head admin={state.type}  />
          <div className='container-fluid'>
          <Body content={props.content}/>
          <Footer />
          </div>
      </GlobalContext>
    </Fragment>
   );
}

export default APP;