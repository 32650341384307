
import logo from '../../../resources/img/logo.png';
import './style.css';
import {useLocation, useResolvedPath} from 'react-router-dom';

const Header = ({admin}) => {

  const {pathname} = useLocation();

  const changeEnvironment = (e) =>{

    if(e.target.value==="null"){return;}

    var url = "";
    if(e.target.value==="prod"){url="https://customers.anyverse.ai"+pathname;}
    if(e.target.value==="dev"){url="https://dev-customers.anyverse.ai"+pathname;}
    if(e.target.value==="test"){url="https://test-customers.anyverse.ai"+pathname;}

    if(url !== ""){ window.location.href = url; }

  }

  return(
    <div className='compHeader mb-2'>
      <div className="row border-av-bottom">
      <div className="col-md-2"></div>
      <div className="col-md-6 mt-2 mb-2">
        <img src={logo} alt="logo" className='logoimg m-2'></img>
      </div>
      <div className="col-md-4 col-md-4 text-right">
        <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-6">
          {(admin &&
            <select className="select-control btn btn-dark" onChange={changeEnvironment}>
              <option value="null">Environment</option>
              <option value="prod">Production</option>
              <option value="dev">Development</option>
              <option value="test">Test</option>
            </select>
          )}
        </div>
        </div>

      </div>
    </div>

    </div>
  );

};

export default Header;