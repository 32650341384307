import { useEffect, useState, useContext } from "react";
import API from "../../../../config/API";
import Loading from '../../../theme/loading';
import {Link, useParams} from 'react-router-dom';
import './style.css';
import {EntornoGlobal} from '../../../../config/globalContext';

const Healthcheck = () => {

    const [statecnx, setStateCnx] = useContext(EntornoGlobal);
    const [error, setError] = useState(undefined);
    const [success, setSuccess] = useState(undefined);
    const [status, setStatus] = useState("loading");
    const [state, setState] = useState({info: {}, name: "", script: "", forceSendStdout: false});

    const {datasetID, batchexecID} = useParams();

    useEffect(async ()=>{

        if(statecnx.user["canApplyHealthCheckForAnnotations"] !== true){
            return setError("You don't have permissions.");
        }

        // Get batchexec
            const batchexec = await API.get(`/v3/api/datasets/${datasetID}/batchexecs/${batchexecID}`);
            if( typeof batchexec === 'string' ){ return setError(batchexec); }
            setState({...state, info: {...batchexec}});

        return setStatus("loaded");

    },[]);

    const updateName = (e) => setState({...state, name: e.target.value});
    const updateScript = (e) => setState({...state, script: e.target.value});

    const sendScript = async () => {

        const res = await API.post(`/v3/api/datasets/healthcheck`, {
            "datasetId": datasetID,
            "batchId": batchexecID,
            "script": state.script,
            "name": state.name,
            "forceSendStdout": state.forceSendStdout,
            "dryRun": false
        });

        if( typeof res === 'string' ){ return setError(res); }

        return setSuccess('The script is running correctly.')

    }

    // THEME
        if(error !== undefined){ return <div className="alert alert-danger">{error}</div> }
        if(success !== undefined){ return <div className="alert alert-success">{success}</div> }
        if(status !== "loaded"){ return <Loading></Loading> }

        return <div className="compCreateHealthCheck">
            
            <h3 className="linkGreen greenanyverse">Healthcheck</h3>
            
            <div className="row mt-4 grey">
                <div className="col-md-1">Dataset</div>
                <div className="col-md-9">{datasetID}</div>
            </div>

            <div className="row mt-3 grey">
                <div className="col-md-1 ">Batch</div>
                <div className="col-md-9">{batchexecID}</div>
            </div>

            <div className="row mt-3 grey">
                <div className="col-md-1 ">Name</div>
                <div className="col-md-9">{state.info.name}</div>
            </div>

            <div className="row mt-3 grey">
                <div className="col-md-1 ">Type</div>
                <div className="col-md-9">{state.info.type}</div>
            </div>

            <div className="mt-5 row">
                <div className="col-md-10">

                    <div>Name:</div>
                    <input type="text" className="form-control" onChange={updateName} />


                    <div className="mt-3">Script:</div>
                    <textarea className="form-control" rows="10" onChange={updateScript}></textarea>

                    <div className="mt-3">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" defaultChecked={state.forceSendStdout} onClick={e=>setState({...state, forceSendStdout: e.target.checked})} />
                        <label className="form-check-label ms-2" htmlFor="flexCheckDefault">Force Send Stdout </label>
                    </div>

                    <div className="text-end">
                        <button className="btn btn-anyverse mt-3" disabled={!(state.script.length && state.name.length)} onClick={sendScript}>Run script</button>
                    </div>
                </div>
            </div>

        </div>;

    }

export default Healthcheck;