import MenuGeneral from '../menu/general';
import {EntornoGlobal} from '../../../config/globalContext';
import {useContext} from 'react';

const Body = (props) => {

  return(
    <div className="row">
      <div className="col-md-2">
        <MenuGeneral/>
      </div>
      <div className="col-md-10 mt-3">
      {props.content}
      </div>
    </div>
  )
};

export default Body;