import { Fragment, useState, useEffect } from "react";
import API from '../../../config/API';
import {useNavigate} from 'react-router-dom';
import CarDesign from "./carDesign";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

const IncabinDesign = () => {
  const [design, setDesign] = useState({});
  const [generationResponse, setGenerationResponse] = useState(false);
  const [iterations, setIterations] = useState(1);
  const [isDay, setIsDay] = useState(true);
  const [batchName, setBatchName] = useState("New_batch");
  const [dataset, setDataset] = useState("");
  const nav = useNavigate();

  
  const [datasets, setDatasets] = useState([]);

  useEffect(async ()=>{
    setDatasets(await API.get('/v3/api/datasets'))
  },[]);

  const onUpdateDesign = (newDesign) => {
    setDesign(newDesign);
  }
  
  const generateSamples = (event) => {
    event.preventDefault();
    setGenerationResponse(true);
    const occupant_distribution = {
      "day": isDay,
      occupancy: Object.keys(design).map( key => { return design[key] } )
    }

    API.post('/v3/api/incabin/generateSamples', {
      data:{
        dataset_name: dataset,
        batch_name: batchName,
        workspace_name: "incabingemini",
        launcher_version: "dev-",
        occupant_distribution: occupant_distribution,
        iterations: iterations
      }
    }).then( (e)=>{
        setGenerationResponse(false);
    } );
  };

  const onDatasetChanged = (ev) => {
    setDataset(ev.target.value);
  }

  return(
    <Fragment>
      <Form onSubmit={generateSamples}>
        <Form.Group className="mb-3">
          <Form.Label>Car distribution</Form.Label>
          <CarDesign onUpdate={onUpdateDesign}/>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Day time</Form.Label>
          <Form.Check type="switch" checked={isDay} onChange={() => setIsDay(!isDay)}/>
        </Form.Group>
        <Form.Select onChange={onDatasetChanged}>
          { datasets.map( dataset => <option>{dataset.name}</option> ) }
        </Form.Select>
        <Form.Group className="mb-3">
          <Form.Label>Batch name</Form.Label>
          <Form.Control type="text" placeholder="Batch name" value={batchName} onChange={(ev) => setBatchName(ev.target.value)} required/>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Number of samples</Form.Label>
          <Form.Control type="number" placeholder="Samples" onChange={(ev) => setIterations(ev.target.value)} required/>
        </Form.Group>
        {
          !generationResponse &&
          <Button type="submit" >Generate</Button>
        }
        {
          generationResponse &&
          <Spinner animation="border" variant="success" />
        }
      </Form>
    </Fragment>
  )
};

export default IncabinDesign;