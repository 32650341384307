import { useEffect, useState } from "react";
import API from "../../../config/API";
import Loading from "../../theme/loading";
import { createGIF } from 'gifshot';

const Iteration = (props) => {

  const [state, setState] = useState({
    state: "not-itinerable", // not-itinerable, itinerable, loading, loaded
    jobs: [],
    job:null,
    camera: {items:[], selected: null},
    output: {items:[], selected: null},
    images: [],
    total: null,
    load_instance: null,
    gif: null
  });

  // Include new jobs
    useEffect(()=>{
      if(state.state !== "not-itinerable"){ return; }
      if(props.jobs.length<2 || props.job === null){ return; }

      let newOptions={};

      if(state.state === "not-itinerable"){
        const changeStatus = isItinerable(props.jobs);
        if(changeStatus){ newOptions.state = "itinerable" }
      }

      if(newOptions?.state === "itinerable"){
        // getCameras
          const cam = getCameras(props.job);
          newOptions.camera = {items:cam, selected:cam[0]}
        // getOutputs
          const out = getOutputs(props.job);
          newOptions.output = {items:out, selected:out[0]}
      }

      setState({...state, jobs: props.jobs, job: props.job, total: props.totalImages, ...newOptions});
      

    },[props.job]);

  // Load images
    useEffect(()=>{
      if(state.state === "loading"){ loadNextImage(); }
    },[state.load_instance, state.images.length]);

  ///////////////////////////////////////////////

  const isItinerable = (jobs=state.jobs) =>{

    const iterations = {};

    // GIF = iteration es el mismo
    jobs.map(e=>{
        const ei = iterations[e.iterationIndex];
        iterations[e.iterationIndex] = (!!ei ? ei+1 : 1);
    });

    return (iterations[0] >= 2);

  }

  ///////////////////////////////////////////////
  
  const getCameras = (job=state.job) => {
    const cameras = [];
    job.files.forEach(e => {
      const cam = e.split("/")[4];
      if(!cameras.includes(cam)){ cameras.push(cam); }
    });
    return cameras;
  }

  ///////////////////////////////////////////////
  
  const getOutputs = (job=state.job) => {
    const outputs = [];
    job.files.forEach(e => {
      const out = e.split("/")[5];
      if(!outputs.includes(out) && out !== "annotations"){
        const cleanOut = out.split("-");
        outputs.push(cleanOut[0]);
      }
    });
    return outputs;
  }

  ///////////////////////////////////////////////
  
  const setCamera = (e) => {
    const value = e.target.value;
    setState({...state, camera: {...state.output, selected: value}});
  }

  ///////////////////////////////////////////////

  const setOutput = (e) => {
    const value = e.target.value;
    setState({...state, output: {...state.output, selected: value}});
  }

  ///////////////////////////////////////////////

  const loadImages = async () => {

    setState({...state, state: "loading", images:[], load_instance: (+new Date())});

  }

  ///////////////////////////////////////////////

  const loadNextImage = async () => {

    // Finish images
      if( state.total === state.images.length ){
        createRealGIF();
        return true;
      }

    const file_split = state.job.files[0].split("/");
    const extension = state.job.files.filter(e=> e.includes(state.output.selected+"-images") )[0].split(".").at(-1);

    const image_count = state.images.length;

    const file = `${file_split[0]}/${file_split[1]}/${file_split[2]}/${image_count}/${state.camera.selected}/${state.output.selected}-images/${state.output.selected}.0.${extension}`;

    const blobURL = await loadOneImage(file, file_split[0]);

    state.images.push(blobURL);

    setState({...state, state: "loading", images: state.images });

  }

  ///////////////////////////////////////////////
  
  const loadOneImage = async (file, datasetID) => {

    const encode = encodeURIComponent(file);
    const img = await API.get(`/v3/api/datasets/${datasetID}/files/${encode}/thumbnail`, {}, 'blob');

    return (img.type === "image/jpeg" ?  URL.createObjectURL(img) : false);

  }

  ///////////////////////////////////////////////

  const createRealGIF = async() => {
    const options = {
      images: state.images,
      gifWidth: 1024,
      gifHeight: 600,
      frameDuration: 0.01,
//      numWorkers: 10,
//      sampleInterval: 10
    };

    createGIF(options, (obj) => {
      if (!obj.error) {
        setState({...state, gif:obj.image,  state: "loaded"});

      }
    });
  }
  
  return <div>
    { (state.state==="itinerable" || state.state==="loading" || state.state==="loaded") && 
      <div>
        <div className="row">
          <div className="col-md-8"></div>
          <div className="col-md-4">
            <div className="text-end"><h5>Create Itineration</h5></div>
            <div className="row">
            <div className="col-md-4"><select className="form-control" id="selCamera" onChange={setCamera}> {state.camera.items.map(e=><option value={e} key={e}>{e}</option>)} </select></div>
            <div className="col-md-4"><select className="form-control" id="selOutput" onChange={setOutput}> {state.output.items.map(e=><option value={e} key={e}>{e}</option>)} </select></div>
              <div className="col-md-4"><button className="btn btn-success form-control" onClick={loadImages} disabled={(state.state==="loading")}>Go!</button></div>
            </div>
          </div>
        </div>

        { // LOADING
        state.state==="loading" && 
        <div>
          <Loading text={state.images.length+"/"+state.total} />
        </div>

        }

        { // LOADED
        state.state==="loaded" && <div className="text-center mt-3">
          <img src={state.gif}/>
        </div>}
      </div>}
    </div>;

}


export default Iteration;