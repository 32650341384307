import { Fragment, useEffect, useState } from "react";
import API from '../../../config/API';
import {useNavigate, useParams} from 'react-router-dom';
import Loading from '../../theme/loading';

const EditMaterialOntology = () => {
  const [state, setState] = useState({status: "loading", loaded:false, disabled:false, name:"", R:"", G:"", B:"", nirReflectance: "", nirOpacity: ""});
  const nav = useNavigate();
  const {id} = useParams();

  useEffect(()=>{
    API.get('/v3/api/materialOntology/'+id).then((data)=>{
        setState({
          ...state, 
          status:"loaded",
          loaded:true,
          name: id,
          R:data.r,
          G:data.g,
          B:data.b,
          nirReflectance: data.nirReflectance,
          nirOpacity: data.nirOpacity
    })
  })

  },[]);

  const EditMaterialOntology = () =>{

    if(validOntology()){
      setState({...state, disabled:true});
      API.put('/v3/api/materialOntology/'+id, {
        r: parseInt(state.R),
        g: parseInt(state.G),
        b: parseInt(state.B),
        nirReflectance: state.nirReflectance != "" ? parseFloat(state.nirReflectance) : null,
        nirOpacity: state.nirOpacity != "" ? parseFloat(state.nirOpacity) : null,
    }).then( (e)=>{

        if(typeof e !== 'string'){
          nav('/app/materialOntology');
        }else{
          setState({...state, disabled:false, alert:"danger", message: e});
        }

      } )

    }

  }

  const removeOntology = () => {

    if(window.confirm("Are you sure?")){
      API.delete('/v3/api/materialOntology/'+id, {}).then(data=>{
        nav('/app/materialOntology');
      })
    }

  }

  const validOntology = () => {
    return (state.name.length>=1 && validRGB()?true:false);
  }

  const validRGB = () =>{
    const R = parseInt(state.R);
    const G = parseInt(state.G);
    const B = parseInt(state.B);

    return (R <= 255 && G <= 255 && B <= 255);
  }

  if(state.status === "loading"){ return <Loading /> }

  return(
    <Fragment>
      <h2>Edit material ontology registry</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <div className="row">
        <div className="col-md-6">
          <h4>Name:</h4>
          <input type="text" id="name" className="form-control" defaultValue={state.name} disabled />

          <h4 className="mt-5">Color:</h4>
          <div className="row">
            <div className="col-md-4"><input type="number" className="form-control" placeholder={"R"} defaultValue={state.R} onChange={(e)=>{setState({...state, R: e.target.value})}}/></div>
            <div className="col-md-4"><input type="number" className="form-control" placeholder={"G"} defaultValue={state.G} onChange={(e)=>{setState({...state, G: e.target.value})}}/></div>
            <div className="col-md-4"><input type="number" className="form-control" placeholder={"B"} defaultValue={state.B} onChange={(e)=>{setState({...state, B: e.target.value})}}/></div>
            <div className="col-md-4"><input type="string" className="form-control" placeholder={"Reflectance"} defaultValue={state.nirReflectance} onChange={(e)=>{setState({...state, nirReflectance: e.target.value})}}/></div>
            <div className="col-md-4"><input type="string" className="form-control" placeholder={"Opacity"} defaultValue={state.nirOpacity} onChange={(e)=>{setState({...state, nirOpacity: e.target.value})}}/></div>
          </div>
          {(validRGB()?<div className="mt-2 border border-white" style={{background:"rgb("+state.R+","+state.G+","+state.B+")", color:"rgb("+state.R+","+state.G+","+state.B+")"}}>█</div>:"")}

          <div className="row mt-5">
            <div className="col-md-6">
              <button className="btn btn-danger" onClick={removeOntology}>Delete registry</button>

            </div>
            <div className="col-md-6 text-end">
            <button className="btn btn-success" onClick={EditMaterialOntology} disabled={(!validOntology())}>Edit registry</button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
};

export default EditMaterialOntology;