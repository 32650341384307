import { useEffect, useState, useContext } from "react";
import API from "../../../../config/API";
import DataTable from "react-data-table-component";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {EntornoGlobal} from '../../../../config/globalContext';
import Loading from '../../../theme/loading';

const ResourceList = (props) => {

  const state_default = {
    status:"loading",
    data:[],
    search:"",
    smartFilterText: "{}",
    tags:[],
    attributes:[],
    easySelected:'tags',
    listeasyquery:[],
    attrSelected:null,
    showFilters: false
  };
  const [statecnx, setStateCnx] = useContext(EntornoGlobal);

  const resource = props.resource;
  const resourceName = props.name;

  const location = useLocation();
  const navi = useNavigate();

  const [state, setState] = useState(
    ( location.state !== null ? location.state : state_default )
  );

    useEffect(()=>{
 
      if(state.data.length === 0){

        API.get('/v3/api/'+resource, {option:"allowedit"}).then((data)=>{
            setState({...state, smartFilter:false, easyFilter: false, status:'loaded', data:data, error:false});
        });

      }
    },[]);

    const filterUsers = () => {

      return state.data.filter((e)=>{

          return ((e.id+e.user+e.name+
            (Array.isArray(e.tags)?e.tags.join("-"):"")
            ).toLocaleLowerCase().indexOf(state.search.toLocaleLowerCase()) !== -1?true:false);

      });

    }


    const doQuery = (query=false) => {

      const json = (query===false?document.getElementById("smartquery").value:state.smartFilterText);

      try {
        var data = {query: JSON.parse(json)};
      } catch (error) {
        return setState({...state, message: "JSON ERROR: Please enter a valid JSON", error:true});
      }

      API.post('/v3/api/'+resource+'/query?option=all&metadata=true', data).then((data)=>{
        const new_state = {...state, data:data, smartFilterText: json, error:false, execQuery:false};
        navi('/app/'+resource, {"state":new_state});
        setState(new_state);
      })

    }

    const easyFilter = async() => {

      if(state.tags.length > 0 ){
        return setState({...state, showFilters: true});
      }

        const tags = (await API.get('/v3/api/'+resource+'/tags')).sort((a,b) => a.localeCompare(b) );
        const attr = (await API.get('/v3/api/'+resource+'/attributes')).sort((a,b) => a.id.localeCompare(b.id) );
        const ontology = await API.get('/v3/api/ontology');
        const authme = await API.get('/v3/api/auth/me');

        const ontology_parse = ontology.map( (e) => { return Object.keys(e)[0]; })

        setState({ ...state, showFilters:true, tags, attributes: attr, ontology: ontology_parse, groups: authme.groups });
    }

    const AddElementToEasyQuery = () => {

      const type = document.getElementById("typeElement").value;
      const name = document.getElementById("nameElement").value;

      if( type === "ontology" ){
        const onto = state.listeasyquery.map(e=>e.type==="ontology");
        if(onto.length>0){ return alert('You only can add one ObjectId')}
      }

      const obj = {
        key: Math.random(),
        type: type,
        name: name
      };

      if(type==="attributes"){ obj.value=document.getElementById("valueElement").value}

      state.listeasyquery.push(obj);

      setState({...state, listeasyquery: state.listeasyquery, smartFilterText: createQuery(true) })

    }

    const chooseAttribute = () => {
      if( state.easySelected === "attributes" ){
        const name = document.getElementById("nameElement").value;
        setState({...state, attrSelected:name});
      }
    }

    const removeSelected = (toremove) => {

      state.listeasyquery = state.listeasyquery.filter(e=>{ return e.key.toString()!==toremove.target.id.toString() });
      setState( { ...state, smartFilterText: createQuery(true)  } );

    }

    const createQuery = (recover=false) => {
      const tofind = state.listeasyquery;

      var query = {};

      // If has tags
        if(tofind.filter(e=>e.type==="tags").length>0){
          query["data.components.TagsComponent.value"] = {"$all":[]};
        }

      // If has groups
        if(tofind.filter(e=>e.type==="groups").length>0){
          query["data.components.ResourceComponent.groups"] = {"$all":[]};
        }

        tofind.forEach(e => {
          // TAG
            if(e.type==="tags"){
              query["data.components.TagsComponent.value"]["$all"].push(e.name);
            }

          // ATTRIBUTE
            if(e.type==="attributes"){
              const text = "data.components.AttributesComponent.value."+e.name+".value";
              if(query[text]){
                // Exist
                  query[text]["$all"].push(e.value);
              }else{
                // Not exist
                  query[text] = {"$all":[e.value]};
              }
            }

          // ONTOLOGY
            if(e.type==="ontology"){
              query["data.components.ObjectIdComponent.value"] = e.name;
            }

          // GROUP
            if(e.type==="groups"){
              query["data.components.ResourceComponent.groups"]["$all"].push(e.name);
            }

        });

      if(recover){
        return JSON.stringify(query);
      }

      setState({...state, smartFilterText: JSON.stringify(query), smartFilter:true, execQuery:true });
    }

    if(state?.execQuery){
      doQuery(state.smartFilterText)
    }

    /* RENDER */
    if(state.status === "loading"){ return <Loading /> }
    if(state.status!=="loaded"){return <div></div>;}

    const columns = [
        { name: 'ID', selector: (row) => <Link to={"/app/"+resource+"/get/"+row.id}>{row.id}</Link>, },
        { name: 'name', selector: (row) => (row.name), sortable: true},
        { name: 'User', selector: row => (row.user), sortable: true, },
        { name: 'Tags', selector: row => ( (Array.isArray(row.tags)?row.tags.join("-"):"") ), },
        { name: 'Creation', selector: row => (row.creation.slice(0,19).replace("T", " ")), sortable: true,},
        { name: 'Updated', selector: row => (row.lastUpdated.slice(0,19).replace("T", " ")), sortable: true,},
        { name: 'Actions', selector: (row) => ( <Link to={"/app/"+resource+"/get/"+row.id}>Details</Link> ),
        }
    ];

    return(
      <div>
        <h2>{resourceName} list ({state?.data?.length?state?.data?.length:0})</h2>

        <div className="row mt-3 mb-3">
          <div className="col-md-12">
            <input type="text" className="form-control" onChange={(e)=>{ setState({...state, search: e.target.value})}} placeholder="Search..." />
          </div>
        </div>

        { !state.showFilters ?
          <div className="grey pointer" onClick={()=>{easyFilter()}} >Filters <span className="fw-bold">&gt;</span></div>
          :
          <div>

            { /* easyFilter*/ }
            <div className="mb-3">
              <div className="row mt-3">
                <div className="col-md-2 text-small grey">Type</div>
                <div className="col-md-3 text-small grey">Value</div>
              </div>

              <div className="row mb-3">
                <div className="col-md-2">
                  <select id="typeElement" className="form-control form-select" onChange={(e)=>{setState({...state, easySelected: e.target.value})}}>
                    <option value="tags">Tags</option>
                    <option value="attributes">Attributes</option>
                    <option value="ontology">ObjectId</option>
                    <option value="groups">Groups</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <select id="nameElement" className="form-control form-select" onChange={chooseAttribute} >
                      <option value="">Select an option</option>
                      {
                        /* TAGS */
                        (state.easySelected==='tags' &&
                            state.tags.map( (e)=>{
                              return <option key={e}>{e}</option>
                            })
                        )
                      }
                      {
                        /* ATTRIBUTES */
                        (state.easySelected==='attributes' &&
                          state.attributes.map( (e)=>{
                            return <option key={e.id}>{e.id}</option>
                          })
                        )
                      }
                      {
                        /* ONTOLOGY */
                        (state.easySelected==='ontology' &&
                          state.ontology.map( (e)=>{
                            return <option key={e}>{e}</option>
                          })
                        )
                      }
                      {
                        /* ONTOLOGY */
                        (state.easySelected==='groups' &&
                          state.groups.map( (e)=>{
                            return <option key={e}>{e}</option>
                          })
                        )
                      }
                  </select>
                </div>
                    {
                      ( (state.easySelected==="attributes" && state.attrSelected!==null) &&
                      <div className="col-md-3"><select id="valueElement" className="form-control" onChange={chooseAttribute} >
                          <option value="">Select an option</option>
                          { state.attributes.filter( (e)=>{return e.id==state.attrSelected} )[0].values.map((e)=>{
                            return <option key={e}>{e}</option>
                          }) }
                        </select></div>
                      )
                    }
                <div className="col-md-3">
                  <button className="btn btn-anyverse" onClick={ AddElementToEasyQuery }>Add</button>
                </div>

              </div>
              
                { state.listeasyquery.map((e)=>{
                  if(e.type==="tags"){
                    return <button className="btn btn-anyverse m-2" key={e.key} id={e.key} onClick={removeSelected} >TAG: {e.name} <span className="text-large ms-2" > ✖ </span></button>
                  }

                  if(e.type==="attributes"){
                    return <button className="btn btn-anyverse m-2" key={e.key} id={e.key} onClick={removeSelected} >ATTR {e.name}: {e.value}</button>
                  }

                  if(e.type==="ontology"){
                    return <button className="btn btn-anyverse m-2" key={e.key} id={e.key} onClick={removeSelected} >ONTOLOGY: {e.name}</button>
                  }

                  if(e.type==="groups"){
                    return <button className="btn btn-anyverse m-2" key={e.key} id={e.key} onClick={removeSelected} >GROUP: {e.name}</button>
                  }                  

                }) }

            </div>


            { /* smartQuery */ }

            <div className="row mt-3 mb-3">
              <div className="col-md-10">
                <input type="text" className="form-control" id="smartquery" placeholder="{smartQuery: true}" value={state.smartFilterText} onChange={(e)=>{ setState({...state, smartFilterText:e.target.value}) }} />
              </div>
              <div className="col-md-2"> <button className="btn btn-anyverse" onClick={ doQuery }>SEARCH</button> </div>
            </div>


          </div>
        }

        {(state.error===true&&<div className="alert alert-danger pointer" onClick={()=>{setState({...state, error:false})}}>{state.message}</div>)}

        {(state.status==='loaded'&&<DataTable
              columns={columns}
              data={filterUsers(state.users)}
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
          />)}
      </div>
    )

}

export default ResourceList;