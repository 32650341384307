import { Fragment, useState } from "react";
import API from '../../../config/API';
import {useNavigate} from 'react-router-dom';

const AddCodeExample = () => {
  const [state, setState] = useState({description:"", code:"", disabled:false, similars: []});
  const nav = useNavigate();

  const createExample = () =>{

    setState({...state, disabled:true});
    API.post('/v3/api/codeassist/addExample', {
      description: state.description,
      code: state.code
    }).then( (e)=>{

      if(typeof e !== 'string'){
        setState({...state, description:"", code:"", disabled:false, alert:"info", message: "Example generated correctly"});
      }else{
        setState({...state, disabled:false, alert:"danger", message: e});
      }

    } )
  };


  const getSimilars = () => {
    setState({...state, disabled:true});
    API.post('/v3/api/codeassist/getSimilar', {
      description: state.description,
      code: state.code
    }).then( (e)=>{

      if(typeof e !== 'string'){
        setState({ ...state, similars: e.response,  disabled:false });
      }else{
        setState({...state, disabled:false, alert:"danger", message: e});
      }

    } )
  };

  const isValidInput = () => {
    return !state.disabled && ( state.description.length >= 1 && state.code.length >= 1 );
  }


  const isValidInputForSimilar = () => {
    return !state.disabled && ( state.description.length >= 1 || state.code.length >= 1 );
  }

  return(
    <Fragment>
      <h2>Add Example</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <div className="container-fluid">
        <div className="row">
          <div className="col">

            <h5>Description:</h5>
            <textarea type="text" id="description" className="form-control" value={state.description} onChange={(e)=>{setState({...state, description: e.target.value})}}/>

            <h5>Code:</h5>
            <textarea  type="text" id="code" className="form-control" value={state.code} onChange={(e)=>{setState({...state, code: e.target.value})}}/>

            <button className="btn btn-success mt-5" onClick={createExample} disabled={!isValidInput()} >Create example</button>

          </div>
        </div>
        <div className="row top-buffer">
          <div className="col top-buffer">
          <h5 data-toggle="tooltip" data-placement="top" title="Get the 3 most similar examples to the content of description and code">Similar examples:</h5>
            <button className="btn btn-success" onClick={getSimilars} disabled={!isValidInputForSimilar()} >Reload similars</button>
            <div>
            {
              state.similars.sort((a,b) => b.score - a.score) && 
              state.similars.map( elem => 
              <div className="container-fluid top-buffer">
                <div class="row">
                  <div class="col form-control" style={ { background:"#000000 !important" } }>
                    { "Score: " + elem.score}
                  </div>
                </div>
                <div>
                  {
                    elem.type &&
                    <div class="row">
                      <div class="col form-control">
                      {elem.type}
                      </div>
                    </div>
                  }
                  {
                    elem.text &&
                    <div class="row">
                      <div class="col form-control break-work">
                      {elem.text}
                      </div>
                    </div>
                  }
                  {
                    elem.code &&
                    <div class="row">
                      <div class="col form-control break-work">
                      {elem.code}
                      </div>
                    </div>
                  }
                  {
                    elem.description &&
                    <div class="row">
                      <div class="col form-control break-work">
                      {elem.description}
                      </div>
                    </div>
                  }
                </div>

              </div>
              )
            }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
};

export default AddCodeExample;