import { Fragment, useState } from "react";
import API from '../../../config/API';
import {useNavigate} from 'react-router-dom';

const CodeAssistant = () => {
  const [state, setState] = useState({prompt:"", response:"", disabled:false});
  const nav = useNavigate();

  const requestResponse = () =>{

    setState({...state, disabled:true});
    API.post('/v3/api/codeassist/query', {
      prompt: state.prompt
    }).then( (e)=>{
      if(typeof e.response === 'string'){
        setState({...state, response:e.response, disabled:false});
      }else{
        setState({...state, disabled:false, alert:"danger", message: e});
      }

    } )
  };

  const isValidInput = () => {
    return !state.disabled && state.prompt.length >= 1;
  }


  return(
    <Fragment>
      <h2>Code assistant</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <div className="row">
        <div className="col-md-6">
          <h4>Prompt:</h4>
          <textarea type="text" id="prompt" className="form-control" value={state.prompt} onChange={(e)=>{setState({...state, prompt: e.target.value})}}/>

          <h4>Response:</h4>
          <textarea readOnly className="form-control" value={state.response}/>

          <button className="btn btn-success mt-5" onClick={requestResponse} disabled={!isValidInput()} >Send</button>
        </div>
      </div>
    </Fragment>
  )
};

export default CodeAssistant;