import { Fragment, useEffect, useState } from "react";
import API from '../../config/API';
import { JsonEditor as Editor } from 'jsoneditor-react';
import './style.css';
import LogoLinux from './test_linux_button.png';
import LogoWindows from './test_windows_button.png';

const Downloads = () => {

  return(
    <div className="compDownload"> 
      <h2>Downloads</h2>

      <div className="subtitle">
        <div className="h5 mt-4">Download Anyverse</div>

        <p>Produce your datasets in the cloud and explore the results in Anyverse, including all the associated ground truth data.<br/>
        Download here and discover a world of possibilities.</p>
      </div>

      <br/>
      <div><a href="https://platform-api.anyverse.ai/v3/installers/anyverse_studio/windows?redirect=true"> <img src={LogoWindows}></img> </a></div>
      <div className="mt-4"><a href="https://platform-api.anyverse.ai/v3/installers/anyverse_studio/linux?redirect=true"> <img src={LogoLinux}></img> </a></div>
    </div>

  )
}

export default Downloads;