import ResourceList from '../../resource/list';

const CharactersList = () => {

      return(
        <div>
          <ResourceList resource="characterAssets" name="Characters"/>
        </div>
      )

}

export default CharactersList;