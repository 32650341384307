import './style.css';

import { useContext, useState } from "react";
import {EntornoGlobal} from '../../../config/globalContext';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import API from '../../../config/API';

const Menu = () => {

  const nav = useNavigate();
  const location = useLocation();
  const path = (location.pathname.split("/").filter(e=>!!e.length).length===1?"main":location.pathname);
  const [statecnx, setStateCnx] = useContext(EntornoGlobal);
  const logout = () => {
    API.remove_token();
    nav('/');
  }

  const menu = [
    {to:"/app/user/me", name:"Personal Info"},
    {to:"/app/user/sessions", name:"Sessions"},
    {to:"/app/datasets", name:"Datasets"}
  ]
  menu.push({to:"#", name:"Ontology", sub: [{to:"/app/ontology", name:"Object Ontology"},{to:"/app/materialOntology", name:"Material Ontology"}] });

  const sub = [
    {to:"/app/assets", name:"Assets"},
    {to:"/app/characterAssets", name:"Characters"},
    {to:"/app/animations", name:"Animations"},
    {to:"/app/scenes", name:"Scenes"},
    {to:"/app/backgrounds", name:"Background"},
    {to:"/app/materials", name:"Materials"},
    {to:"/app/templates", name:"Templates"}];

  if(statecnx?.user.canUploadAnimations ||
    statecnx?.user.canUploadCharacterAssets ||
    statecnx?.user.canUploadMaterials ||
    statecnx?.user.canUploadScenes ||
    statecnx?.user.canUploadAssets ||
    statecnx?.user.canUploadBackgrounds){

      sub.push( {to:"/app/pipe", name:"Pipe resource", class:"fst-italic"} );
    }

  menu.push({to:"#", name:"Resources", sub });


  if( statecnx?.user.canUseUploadCodeExamples || statecnx?.user.canUseCodeAssistant ){
    const sub = [];
    if(statecnx?.user.canUseUploadCodeExamples )
    {
      sub.push({to:"/app/codeassistant/add", name:"Code Examples"});
    }
      
    if(statecnx?.user.canUseCodeAssistant )
    {
      sub.push({to:"/app/codeassistant", name:"Code Assistant"});
      
    } 
    menu.push({to:"#", name:"Assistant", sub});
  }

  if( statecnx?.user.canUseInCabinGeneration ){
    const sub = [];
    sub.push({to:"/app/incabin", name:"Incabin From Image"});
    sub.push({to:"/app/incabindesign", name:"Incabin Design"});
    menu.push({to:"#", name:"Incabin", sub});
  }


  menu.push({to:"/app/downloads", name:"Installers"});

  const [state, setState] = useState({menu});
  const clickMenu = (name) => { setState({...state, [name+"drowpdown"]: !state[name+"drowpdown"]}); }

  return(
    <div className="compMenu">

      <nav className="navbar navbar-expand-lg navbar-dark">
        <button className="navbar-toggler form-control" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <div id="telita">
            <a className="navbar-brand text-dark" href="#">Menú</a>
            <span className="navbar-toggler-icon"></span>
          </div>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="nav flex-column">
            {state.menu.map((e)=>{
              if(!e?.sub){
                return <Link key={e.name} className={"nav-link mb-2 "+(e.to.startsWith(path)&&"currentlink")} to={e.to}>{e.name}</Link>
              }
              
              return <div key={e.name} className='submenu'>
                <Link
                  className={"nav-link mb-2 "+(e.to.startsWith(path)&&"currentlink")}
                  to={e.to}
                  onClick={() => clickMenu(e.name)}
                  >{e.name} <i className='arrow'></i></Link>
                <div style={{display:"none"}} className={" "+(state[e.name+"drowpdown"]?"solid":"")}>
                {e.sub.map(e2=>{
                  return <div key={"sub"+e2.name} className='submenulink'><Link key={e2.name} className={"nav-link mb-2 "+(e2.to.startsWith(path)&&"currentlink")+" "+(e2?.class?e2.class:"")} to={e2.to}>{e2.name}</Link></div>
                })}
                </div>
              </div>
            })}
            <div className="nav-link mb-2 logout" onClick={logout}>Logout</div>
          </div>
        </div>
      </nav>

    </div>
  );

};

export default Menu;