import { useState, useEffect  } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Seat from "./seat";

const CarDesign = ({onUpdate}) => {

  const [seats, setSeats] = useState({});
  const [seat01, setSeat01] = useState({});
  const [seat02, setSeat02] = useState({});
  const [seat03, setSeat03] = useState({});
  const [seat04, setSeat04] = useState({});
  const [seat05, setSeat05] = useState({});

  const seatMap = {
    "01": setSeat01,
    "02": setSeat02,
    "03": setSeat03,
    "04": setSeat04,
    "05": setSeat05,
  }

  useEffect(() => {
    onUpdate(seats);
  }, [seats]);

  const seatUpdated = (data, seatNumber) => {
    seatMap[seatNumber](data)
    const allSeats = {
      "01": seat01,
      "02": seat02,
      "03": seat03,
      "04": seat04,
      "05": seat05,
    };
    allSeats[seatNumber] = data;
    setSeats(allSeats);
  };

  return(
    <div>
      <Container>
        <Row  className="mb-4" md={6}>
          <Col sm={4}>
            <Seat onUpdate={seatUpdated} seatNumber={"05"} />
          </Col>
          <Col sm={4}>
            <Seat onUpdate={seatUpdated} seatNumber={"04"} />
          </Col>
          <Col sm={4}>
            <Seat onUpdate={seatUpdated} seatNumber={"03"} />
          </Col>
        </Row>
        <Row md={6}>
          <Col sm={4}>
            <Seat onUpdate={seatUpdated} seatNumber={"02"} />
          </Col>
          <Col sm={4}/>
          <Col sm={4}>
            <Seat onUpdate={seatUpdated} seatNumber={"01"} />
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default CarDesign;