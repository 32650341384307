import { Fragment, useState } from "react";
import API from '../../../../config/API';
import {useNavigate} from 'react-router-dom';
import { JsonEditor as Editor } from 'jsoneditor-react';
import {slugify} from '../../../../config/funcaux';

const AddDynamic = () => {
  const [state, setState] = useState({disabled:false, data: {value:null, flags:{}}, idDynamic:""});
  const nav = useNavigate();

  const createDynamic = () =>{

    if(state.idDynamic === ""){ return alert("ID is required"); }

    API.post('/v3/api/utils/dynamicresource/'+state.idDynamic, state.data).then((data)=>{
      console.log(data);
      nav('/app/dynamicresource/');
    });

  }

  const updateData = (json) => {
    setState({...state, data:json});
  }

  return(
    <Fragment>
      <h2>Add Dynamic Resource</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <div className="row">
        <div className="col-md-6">
          <h4>ID:</h4>
          <input type="text" id="idDynamic" className="form-control" onChange={(e)=>{ setState({...state, idDynamic: slugify(e.target.value)}) }}/>
          <p className="ms-2 mt-2 fw-lighter">{slugify(state.idDynamic)}</p>
          <br/><br/>
          <Editor
            value={state.data}
            onChange={updateData}
          />
          <br/><br/>
          <button className="btn btn-success" onClick={createDynamic} disabled={state.disabled}>Create item</button>
        </div>
      </div>
    </Fragment>
  )
};

export default AddDynamic;