import { Fragment, useEffect, useState } from "react";
import API from '../../../config/API';
import {useNavigate, useParams} from 'react-router-dom';
import Loading from '../../theme/loading';

const EditOntology = () => {
  const [state, setState] = useState({status: "loading", loaded:false, disabled:false, name:"", R:"", G:"", B:"", instance:false, roadLayer:false});
  const nav = useNavigate();
  const {id} = useParams();

  useEffect(()=>{
    API.get('/v3/api/ontology/'+id).then((data)=>{
        setState({...state, status:"loaded", loaded:true, name: id, R:data.r, G:data.g, B:data.b, instance:data.instance, roadLayer: data.roadLayer});
    })

  },[]);

  const EditOntology = () =>{

    if(validOntology()){
      setState({...state, disabled:true});
      API.put('/v3/api/ontology/'+id, {
        r: parseInt(state.R),
        g: parseInt(state.G),
        b: parseInt(state.B),
        instance: state.instance,
        roadLayer: state.roadLayer
      }).then( (e)=>{

        if(typeof e !== 'string'){
          nav('/app/ontology');
        }else{
          setState({...state, disabled:false, alert:"danger", message: e});
        }

      } )

    }

  }

  const removeOntology = () => {

    if(window.confirm("Are you sure?")){
      API.delete('/v3/api/ontology/'+id, {}).then(data=>{
        nav('/app/ontology');
      })
    }

  }

  const validOntology = () => {
    return (state.name.length>=1 && validRGB()?true:false);
  }

  const validRGB = () =>{
    const R = parseInt(state.R);
    const G = parseInt(state.G);
    const B = parseInt(state.B);

    return (R <= 255 && G <= 255 && B <= 255);
  }

  if(state.status === "loading"){ return <Loading /> }

  return(
    <Fragment>
      <h2>Edit registry</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <div className="row">
        <div className="col-md-6">
          <h4>Name:</h4>
          <input type="text" id="name" className="form-control" defaultValue={state.name} disabled />

          <h4 className="mt-5">Color:</h4>
          <div className="row">
            <div className="col-md-4"><input type="number" className="form-control" placeholder={"R"} defaultValue={state.R} onChange={(e)=>{setState({...state, R: e.target.value})}}/></div>
            <div className="col-md-4"><input type="number" className="form-control" placeholder={"G"} defaultValue={state.G} onChange={(e)=>{setState({...state, G: e.target.value})}}/></div>
            <div className="col-md-4"><input type="number" className="form-control" placeholder={"B"} defaultValue={state.B} onChange={(e)=>{setState({...state, B: e.target.value})}}/></div>
          </div>
          {(validRGB()?<div className="mt-2 border border-white" style={{background:"rgb("+state.R+","+state.G+","+state.B+")", color:"rgb("+state.R+","+state.G+","+state.B+")"}}>█</div>:"")}

          <h4 className="mt-5">Instance:</h4>
          <select className="form-control" defaultValue={(state.instance?"true":"false")} onChange={(e)=>{setState({...state, instance:(e.target.value==="true"?true:false)})}}>
            <option value="false">False</option>
            <option value="true">True</option>
          </select>


          <h4 className="mt-5">Road Layer:</h4>
          <select className="form-control" defaultValue={(state.roadLayer?"true":"false")} onChange={(e)=>{setState({...state, roadLayer:(e.target.value==="true"?true:false)})}}>
            <option value="false">False</option>
            <option value="true">True</option>
          </select>


          <div className="row mt-5">
            <div className="col-md-6">
              <button className="btn btn-danger" onClick={removeOntology}>Delete registry</button>

            </div>
            <div className="col-md-6 text-end">
            <button className="btn btn-success" onClick={EditOntology} disabled={(!validOntology())}>Edit registry</button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
};

export default EditOntology;