import { Fragment, useEffect, useState } from "react";
import API from '../../../config/API';
import { JsonEditor as Editor } from 'jsoneditor-react';
import './style.css';
import Loading from '../../theme/loading';

const GetUser = () => {

  const [state, setState] = useState({status:"loading"});
  const [waiting, setWaiting] = useState(false);

  useEffect(async ()=>{
    // const usage = await API.get('/v3/api/auth/usage', {});
    // const quotas = await API.get('/v3/api/auth/quotas', {});

    const info = await API.get('/v3/api/auth/info', {});

    if( typeof info === 'string' ){
      setState({...state, status:'error'});
    }else{
      setState({...state, status:'loaded', info: info.data, toSave: []});
    }

  },[]);

  const updateField = (e) =>{
    const ID = e.target.id;
    const value = e.target.value;

    // Add toSave (for effect)
      if( !state.toSave.includes(ID) ){ state.toSave.push(ID); }
      
    state.info[ID] = value;

    setState({...state});
  }

  const save = async () => {

    if( state.toSave.length === 0 ){ return; }

    const obj = {};

    state.toSave.forEach(e => {
      obj[e] = state.info[e];
    });

    setWaiting(true);

    const info = await API.put('/v3/api/auth/info', obj).then(e=>{

      if(e?.status){
        setState({...state, toSave:[], alert:{type:"success", message: "Personal data has been updated successfully." }})
      }else{
        setState({...state, toSave:[], alert:{type:"danger", message: "An error occurred while updating your data." }})
      }

      setWaiting(false);
    })

  }

  const theme = (name,id) => <div className="row mt-2" key={id}>
    <div className="col-md-2"> {name} </div>
    <div className="col-md-3">
      <input
      type="text"
      id={id}
      value={ (typeof state.info[id] === "string" ? state.info[id] : "") }
      className={"form-control "+(state.toSave.includes(id)?"border-warning":"")}
      onChange={updateField}
      />
      </div>
    </div>

  if(state.status === "loading"){ return <Loading></Loading>;}

  if(state.status === "error"){ return <div>
      <div className="row">
        <div className="col-md-3 fw-bold"> <h2>Personal info</h2> </div>
      </div>
      <div className={"alert alert-danger mt-5"}>Data personal editing is not enabled, please contact customer support.</div>
  </div>;}



  return(
    <div className="compPersonalInfo">

      <div className="row">
        <div className="col-md-3 fw-bold"> <h2>Personal info</h2> </div>
        <div className="col-md-2 text-end"> <button className="btn btn-anyverse ps-4 pe-4" onClick={save}>Update</button> </div>
      </div>

    
      {(waiting ? <Loading text=" "></Loading>:<div>

        {state?.alert && <div className={"alert alert-"+state.alert.type+" mt-5"}>{state.alert.message}</div>}
        <br/>

        {theme("Name", "firstname")}
        {theme("Last name", "lastname")}
        {theme("Mobile", "mobilephone")}
        {theme("Phone", "phone")}

        <div className="row mt-4"> <div className="col-md-5"><hr/></div> </div>
        <div className="h5 mt-3 mb-5">Company info</div>

        {theme("Company", "company")}
        {theme("Industry", "industry")}
        {theme("Job title", "jobtitle")}
        {theme("Street address", "address")}
        {theme("City", "city")}
        {theme("Country/Region", "country")}
        {theme("Postal code", "zip")}
        {theme("Fax", "fax")}

        <div className="row mt-4"> <div className="col-md-5"><hr/></div> </div>
        <div className="h5 mt-3 mb-5">Social media info</div>

        {theme("Linkedin", "linkedin")}
        {theme("Twitter", "twitterhandle")}
        {theme("Website URL", "website")}

            <div className="row mt-2 mb-5">
              <div className="col-md-2"></div>
              <div className="col-md-3 text-end">  </div>
            </div>
      </div>)}
    </div>
  )
}

export default GetUser;