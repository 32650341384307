import { useEffect, useState, useContext } from "react";
import API from "../../../../config/API";
import Loading from '../../../theme/loading';
import {Link, useParams} from 'react-router-dom';
import './style.css';
import {EntornoGlobal} from '../../../../config/globalContext';
import DataTable from "react-data-table-component";

const Healthcheck = () => {

    const [statecnx, setStateCnx] = useContext(EntornoGlobal);
    const [error, setError] = useState(undefined);
    const [success, setSuccess] = useState(undefined);
    const [status, setStatus] = useState("loading");
    const [state, setState] = useState({
        batchexec: {},
        healthchecks:[],
        healthcheck: {
            id: undefined
        }
    });

    const pagination = 200;

    const {datasetID, batchexecID} = useParams();

    useEffect(async ()=>{

        if(statecnx.user["canApplyHealthCheckForAnnotations"] !== true){
            return setError("You don't have permissions.");
        }

        // Get batchexec
            const batchexec = await API.get(`/v3/api/datasets/${datasetID}/batchexecs/${batchexecID}`);
            if( typeof batchexec === 'string' ){ return setError(batchexec); }

        // Get healthchecks
            const healthchecks = await API.get(`/v3/api/datasets/healthcheck/batchexec/${batchexecID}/resume`);
            if( typeof batchexec === 'string' ){ return setError(healthchecks); }
            
        setState({...state, batchexec, healthchecks});

        return setStatus("loaded");

    },[]);

    const viewHC = async (healthcheck) => {

        const hc_items = await API.get(`/v3/api/datasets/healthcheckresults/pagination/0/`+pagination, {
            datasetId: datasetID,
            batchId: batchexecID,
            healthcheckId : healthcheck._id
        });

        setState({...state, healthcheck: {
            id: healthcheck._id,
            info: healthcheck,
            items: hc_items,
            open_item: undefined
        }})

    }

    const loadMore = async() => {

        const nextPage = state.healthcheck.items.length / pagination ;

        const hc_items = await API.get(`/v3/api/datasets/healthcheckresults/pagination/${nextPage}/`+pagination, {
            datasetId: datasetID,
            batchId: batchexecID,
            healthcheckId : state.healthcheck.id
        });

        state.healthcheck.items = state.healthcheck.items.concat(hc_items);

        setState({...state});

    }

    const openResult = async (result) =>{
        setState({...state, healthcheck:{...state.healthcheck, open_item: result}});
        console.log("result", result);
    }

    const getResultCount = () => state.healthcheck?.info?.resultCount;

    console.log(state);


    // THEME
        if(error !== undefined){ return <div className="alert alert-danger">{error}</div> }
        if(success !== undefined){ return <div className="alert alert-success">{success}</div> }
        if(status !== "loaded"){ return <Loading></Loading> }

        return <div className="compViewHealthCheck">
            
            <h3 className="linkGreen greenanyverse">Healthcheck</h3>
            
            <div className="row mt-4 grey">
                <div className="col-md-1">Dataset</div>
                <div className="col-md-9">{datasetID}</div>
            </div>

            <div className="row mt-3 grey">
                <div className="col-md-1 ">Batch</div>
                <div className="col-md-9">{batchexecID}</div>
            </div>

            <div className="mt-5">

                <DataTable
                    columns={[
                        { name: 'Name', selector: (row) => (row?.data?.name || row._id), sortable: true, },
                        { name: 'Count', selector: (row) => (row?.count || 0)},
                        { name: 'Result Count', selector: (row) => (row?.resultCount || 0)},
                        { name: 'View', selector: (row) => <span onClick={()=>{viewHC(row)}} className="greenanyverse pointer">View</span>}
                    ]}
                    data={state.healthchecks}
                />

            </div>

            {state?.healthcheck?.id && <div className="mt-5">

                <hr></hr>

                <div className="row mt-4 grey">
                    <div className="col-md-1">Name</div>
                    <div className="col-md-9">{state?.healthcheck?.info?.data?.name || state?.healthcheck?.info?._id}</div>
                </div>

                <div className="row mt-4 grey">
                    <div className="col-md-1">Status</div>
                    <div className="col-md-9">{state?.healthcheck?.info?.status}</div>
                </div>

                {/*<div className="row mt-4 grey">
                    <div className="col-md-1">Script</div>
                    <div className="col-md-9"> <span className="greenanyverse pointer">open script</span></div>
                </div>*/}

                <div className="mt-3">{state.healthcheck.items.map(hc=>{
                    const color = (hc.sample_ok===false || hc.executionFailed===true)?'error':'success';
                    return <span onClick={()=>{openResult(hc)}} className={"pointer "+color} >■</span>
                })}
                
                <span> {(state.healthcheck.items.length < getResultCount() && <span onClick={loadMore} className="paginator">{">"}</span> )} </span>
                </div>

                { state?.healthcheck?.open_item && 
                <div className="row mt-3 mb-5">
                    <hr></hr>

                    <div className="col-md-6">

                        <h3 className="greenanyverse">Result</h3>

                        <div className="row mt-4 grey">
                            <div className="col-md-2">jobId</div>
                            <div className="col-md-9">{state.healthcheck.open_item.jobId}</div>
                        </div>

                        <div className="row mt-4 grey">
                            <div className="col-md-2">Camera</div>
                            <div className="col-md-9">{state.healthcheck.open_item.camera}</div>
                        </div>

                        <div className="row mt-4 grey">
                            <div className="col-md-2">Capture</div>
                            <div className="col-md-9">{state.healthcheck.open_item.captureIndex}</div>
                        </div>

                        <div className="row mt-4 grey">
                            <div className="col-md-2">Iteration</div>
                            <div className="col-md-9">{state.healthcheck.open_item.iterationIndex}</div>
                        </div>

                        <div className="row mt-4 grey">
                            <div className="col-md-2">execution<br></br>Failed</div>
                            <div className="col-md-9">{state.healthcheck.open_item.executionFailed?'true':'false'}</div>
                        </div>

                        <div className="row mt-4 grey">
                            <div className="col-md-2">SampleOk</div>
                            <div className="col-md-9">{state.healthcheck.open_item.sample_ok?'true':'false'}</div>
                        </div>

                    </div>

                    <div className="col-md-6">

                        <h3 className="greenanyverse">Errors</h3>

                        {Array.isArray(state.healthcheck.open_item.errors) &&
                        state.healthcheck.open_item.errors.map(e=><div><div>
                            <span className="errorcode">{e?.error}</span> {e?.message}</div>
                            <hr></hr>
                        </div>) }

                    </div>

                </div> }

            </div>}

        </div>;

    }

export default Healthcheck;