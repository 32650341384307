import { Fragment, useEffect, useState } from "react";
import API from '../../../../config/API';
import {useNavigate, useParams} from 'react-router-dom';
import { JsonEditor as Editor } from 'jsoneditor-react';
import {slugify} from '../../../../config/funcaux';
import Loading from '../../../theme/loading';

const EditConfig = () => {
  const {id} = useParams();
  const [state, setState] = useState( { status: "loading", id: id } );
  const nav = useNavigate();

  console.log("props", state);

  useEffect(()=>{

    API.get('/v3/api/utils/configurations/'+state.id, {}).then((data)=>{
      const _id = data._id;
      delete data._id;
      setState({...data, id:_id, data, status:"loaded"});
    });

  },[]);


  const updateConfig = () =>{

    if(state.idconfig === ""){ return alert("ID is required"); }
    console.log(state.data);
    API.put('/v3/api/utils/configurations/'+state.id, state.data).then((data)=>{
      console.log(data);
      //nav('/app/configuration/');
    });

  }

  const updateData = (json) => {
    setState({...state, data:json});
  }

  if(state.status === "loading"){ return <Loading /> }

  return(
    <Fragment>
      <h2>Edit Config</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <div className="row">
        <div className="col-md-6">
          <h4>ID:</h4>
          <input type="text" id="IDconfig" value={state.id} className="form-control" disabled/>
          <br/><br/>
          <Editor
            value={state.data}
            onChange={updateData}
          />
          <br/><br/>
          <button className="btn btn-success" onClick={updateConfig} disabled={state.disabled}>Update config</button>
        </div>
      </div>
    </Fragment>
  )
};

export default EditConfig;