import ResourceGet from '../../resource/get';

const AssetsGet = () => {

      return(
        <div>
          <ResourceGet resource="backgrounds" name="Backgrounds" />
        </div>
      )

}

export default AssetsGet;