import './styles.css';
import logo from '../../resources/img/logo.png';
import {useState} from 'react';
import API from '../../config/API';
import Header from '../../components/theme/header';

const RecoveryPassword = (props) =>{

	const [state, setState] = useState({
		status: true, // false, 'success', 'fail'
		message: null,
		email: ""
	});

	const validateEmail = (email) => {
		return /\S+@\S+\.\S+/.test(email);
	}

	const sendMail = () =>{

		const email = document.getElementById("email").value;


		if( validateEmail(email) ){

			API.post('/v3/api/auth/recoverypassword',{email:email}).then((resp)=>{
				setState({status: 'success', message: "We'll email you a password reset link." });
			}).catch((e)=>{
				setState({...state, status: 'danger', message: "We have detected an internal error, please try again later." });
			})

		}else{
			setState({ status: 'danger', message: "This email is invalid." });
		}

	}

	return (
		<>
		<Header/>
		<div className="mt-5 compRecoveryPassword">
			<div className="row">
				<div className='col-md-2'></div>
				<div className="col-md-5">
					<h4 className='mt-2'>Password recovery</h4>

					{(state.status !== false &&
						<div className={"alert alert-"+state.status+" mt-3"}>{state.message}</div>
					)}

					<div className='mt-2'>Email:</div>
					<input type="email" id="email" name="email" className='form-control' placeholder='example@mymail.com' onChange={(e)=>{setState({...state, email:e.target.value})}}></input>
					<br></br>
					<input type="button" onClick={sendMail} className='btn btn-anyverse form-control' value="RESET PASSWORD" disabled={(validateEmail(state.email)?false:true)}></input>
				</div>
			</div>
		</div>
		</>
	);
}

export default RecoveryPassword;