import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import API from './config/API';
import { GlobalDebug } from "./config/globalDebug";
import './style.css';
import "@fontsource/poppins/200.css"; // Specify weight
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/600.css"; // Specify weight


// Pages
	import App from './pages/app';
	import Login from './pages/login';
	import Recovery from './pages/recoveryPassword';

// Content
  import MeUsers from './components/users/me';
  import TokenListAdmin from './components/users/tokenslist/index.admin';
  import TokenListUsers from './components/users/tokenslist/index.user';
  import TestCICD from './components/testCICD';
  import Assets from './components/Resources/assets/list';
  import AssetsGet from './components/Resources/assets/get';
  import Animations from './components/Resources/animations/list';
  import AnimationsGet from './components/Resources/animations/get';
  import Characters from './components/Resources/characterAssets/list';
  import CharactersGet from './components/Resources/characterAssets/get';
  import Scenes from './components/Resources/scenes/list';
  import ScenesGet from './components/Resources/scenes/get';
  import Materials from './components/Resources/materials/list';
  import MaterialsGet from './components/Resources/materials/get';
  import Backgrounds from './components/Resources/backgrounds/list';
  import BackgroundsGet from './components/Resources/backgrounds/get';
  import Templates from './components/Resources/templates/list';
  import TemplatesGet from './components/Resources/templates/get';
  import ListThemes from './components/themehtml/list';
  import AddTheme from './components/themehtml/add';
  import EditTheme from './components/themehtml/edit';
  import ListOntology from './components/ontology/list';
  import AddOntology from './components/ontology/add';
  import EditOntology from './components/ontology/edit';
  import ResourcePipe from './components/Resources/pipe';

  import AddExample from './components/codeassist/add';
  import CodeAssistant from './components/codeassist/use';
  import Incabin from './components/incabin/use';
  import IncabinDesign from './components/incabin/design';

	import ListMaterialOntology from './components/materialOntology/list';
  import AddMaterialOntology from './components/materialOntology/add';
  import EditMaterialOntology from './components/materialOntology/edit';

  import ConfList from './components/config/configuration/list';
  import ConfAdd from './components/config/configuration/add';
  import ConfEdit from './components/config/configuration/edit';
  import DynamicList from './components/config/dynamicresources/list';
  import DynamicAdd from './components/config/dynamicresources/add';
  import DynamicEdit from './components/config/dynamicresources/edit';

  import Dataset from './components/datasets';
  import DatasetGet from './components/datasets/get';
  import DatasetBatchExec from './components/datasets/batchexec';
  import CreateHealthCheck from './components/datasets/batchexec/createhealthcheck';
  import ViewHealthCheck from './components/datasets/batchexec/viewhealthcheck';
  import Downloads from './components/download';
  import ReactGA from "react-ga4";


  ReactGA.initialize("G-583G1RCL5X");
  
const wlhost = window.location.hostname;
if( !(wlhost.startsWith("test-") || wlhost.startsWith("dev-") || wlhost.startsWith("local")) ){
	GlobalDebug(false);
}


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
		<Routes>
			<Route path="/" element={<Login/>}></Route>
			<Route path="/app" element={<App/>}></Route>
			<Route path="/recovery-password" element={<Recovery/>}></Route>
			<Route path="/app/user/me" element={<App content={<MeUsers />}/>}></Route>
			<Route path="/app/user/sessions" element={<App content={<TokenListUsers />}/>}></Route>
			<Route path="/app/user/tokens/:id" element={<App content={<TokenListAdmin />}/>}></Route>
			<Route path="/app/testCICD" element={<App content={<TestCICD />}/>}></Route>
			<Route path="/app/assets" element={<App content={<Assets />}/>}></Route>
			<Route path="/app/assets/get/:id" element={<App content={<AssetsGet />}/>}></Route>
			<Route path="/app/animations" element={<App content={<Animations />}/>}></Route>
			<Route path="/app/animations/get/:id" element={<App content={<AnimationsGet />}/>}></Route>
			<Route path="/app/characterAssets" element={<App content={<Characters />}/>}></Route>
			<Route path="/app/characterAssets/get/:id" element={<App content={<CharactersGet />}/>}></Route>
			<Route path="/app/scenes" element={<App content={<Scenes />}/>}></Route>
			<Route path="/app/scenes/get/:id" element={<App content={<ScenesGet />}/>}></Route>
			<Route path="/app/materials" element={<App content={<Materials />}/>}></Route>
			<Route path="/app/materials/get/:id" element={<App content={<MaterialsGet />}/>}></Route>
			<Route path="/app/backgrounds" element={<App content={<Backgrounds />}/>}></Route>
			<Route path="/app/backgrounds/get/:id" element={<App content={<BackgroundsGet />}/>}></Route>
			<Route path="/app/templates" element={<App content={<Templates />}/>}></Route>
			<Route path="/app/templates/get/:id" element={<App content={<TemplatesGet />}/>}></Route>
			<Route path="/app/pipe" element={<App content={<ResourcePipe />}/>}></Route>
			<Route path="/app/themes" element={<App content={<ListThemes />}/>}></Route>
			<Route path="/app/themes/add" element={<App content={<AddTheme />}/>}></Route>
			<Route path="/app/themes/edit/:id" element={<App content={<EditTheme />}/>}></Route>
			<Route path="/app/ontology" element={<App content={<ListOntology />}/>}></Route>
			<Route path="/app/ontology/add" element={<App content={<AddOntology />}/>}></Route>
			<Route path="/app/ontology/edit/:id" element={<App content={<EditOntology />}/>}></Route>

			<Route path="/app/codeassistant/add" element={<App content={<AddExample />}/>}></Route>
			<Route path="/app/codeassistant" element={<App content={<CodeAssistant />}/>}></Route>
			<Route path="/app/incabin" element={<App content={<Incabin />}/>}></Route>
			<Route path="/app/incabindesign" element={<App content={<IncabinDesign />}/>}></Route>

			<Route path="/app/materialOntology" element={<App content={<ListMaterialOntology />}/>}></Route>
			<Route path="/app/materialOntology/add" element={<App content={<AddMaterialOntology />}/>}></Route>
			<Route path="/app/materialOntology/edit/:id" element={<App content={<EditMaterialOntology />}/>}></Route>

			<Route path="/app/configuration" element={<App content={<ConfList />}/>}></Route>
			<Route path="/app/configuration/add" element={<App content={<ConfAdd />}/>}></Route>
			<Route path="/app/configuration/edit/:id" element={<App content={<ConfEdit />}/>}></Route>
			<Route path="/app/dynamicresource" element={<App content={<DynamicList />}/>}></Route>
			<Route path="/app/dynamicresource/add" element={<App content={<DynamicAdd />}/>}></Route>
			<Route path="/app/dynamicresource/edit/:id" element={<App content={<DynamicEdit />}/>}></Route>

			<Route path="/app/datasets" element={<App content={<Dataset />}/>}></Route>
			<Route path="/app/datasets/:id" element={<App content={<DatasetGet />}/>}></Route>
			<Route path="/app/datasets/:datasetID/batchexec/:batchexecID" element={<App content={<DatasetBatchExec />}/>}></Route>
			<Route path="/app/datasets/:datasetID/batchexec/:batchexecID/healthcheck" element={<App content={<CreateHealthCheck />}/>}></Route>
			<Route path="/app/datasets/:datasetID/batchexec/:batchexecID/viewhealthchecks" element={<App content={<ViewHealthCheck />}/>}></Route>

			<Route path="/app/downloads" element={<App content={<Downloads />}/>}></Route>
			
		</Routes>
	</BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);