import { useEffect, useState } from "react";
import API from "../../config/API";
import DataTable from "react-data-table-component";
import Loading from '../theme/loading';
import {Link, useLocation, useNavigate} from 'react-router-dom';

const Dataset = () => {

    const [state, setState] = useState({state:"loading", search:""});

    useEffect(async ()=>{
      const datasets = await API.get('/v3/api/datasets');

      setState({...state, state:"loaded", data:datasets});
    },[]);

    // FILTER DATA (SEARCH)
        const filterData = () => state.data.filter((e)=>{
            return ((e.id+e.name+e.type+e.description).toLocaleLowerCase().indexOf(state.search.toLocaleLowerCase()) !== -1?true:false);
        });


    // THEME
        if(state.state !== "loaded"){ return <Loading></Loading> }

        return <div>
            <h2>Datasets ({state?.data?.length?state?.data?.length:0})</h2>

            <div className="row mt-3 mb-3">
            <div className="col-md-12">
                <input type="text" className="form-control" onChange={(e)=>{ setState({...state, search: e.target.value})}} placeholder="Search..." />
            </div>
            </div>
            <DataTable
            columns={[
                { name: 'ID', selector: (row) => (<Link to={"/app/datasets/"+row.id}>{row.id}</Link>), },
                { name: 'Name', selector: (row) => (row.name), sortable: true},
                { name: 'Type', selector: (row) => (row.type), sortable: true},
                { name: 'Creation', selector: row => (row.creation.slice(0,10)), sortable: true,}
            ]}
            data={filterData(state.data)}
        />
        </div>
}

export default Dataset;