import { useEffect, useState, useContext } from "react";
import API from "../../../config/API";
import DataTable from "react-data-table-component";
import Loading from '../../theme/loading';
import {Link, useParams} from 'react-router-dom';
import JSONviewer from '../../../helpers/jsonviewer';
import {EntornoGlobal} from '../../../config/globalContext';

import './style.css';

const Dataset = () => {
    const [statecnx, setStateCnx] = useContext(EntornoGlobal);

    const [state, setState] = useState({state:"loading", search:""});
    const {id} = useParams();

    useEffect(async ()=>{
        const dataset = await API.get('/v3/api/datasets/'+id);
        const batchexecs = await API.get(`/v3/api/datasets/${id}/batchexecs`);
        setState({...state, data: batchexecs, dataset: dataset, state:"loaded"});
    },[]);

    // FILTER DATA (SEARCH)
        const filterData = () => state.data.filter((e)=>{
            return ((e.id+e.name+e.state+e.description).toLocaleLowerCase().indexOf(state.search.toLocaleLowerCase()) !== -1?true:false);
        });

        console.log(state);

    // THEME
        if(state.state !== "loaded"){ return <Loading></Loading> }

        return <div className="compDatasetGet">
            <h2>{state.dataset.name}</h2>
            <div className="row mt-3">
                <div className="col-md-1 grey">Dataset</div>
                <div className="col-md-9 grey">{id}</div>
            </div>
            <div className="row mt-3">
                <div className="col-md-1 grey">Output</div>
                <div className="col-md-9"> {Object.keys(state.dataset.data.components.DatasetOutputsConfigurationComponent).map(e=>{
                        const tmp = state.dataset.data.components.DatasetOutputsConfigurationComponent;
                        if(tmp[e] === true){
                            return <span className="buttongrey p-1 m-2">{e}</span>;
                        }
                    })}</div>
            </div>

            <div className="row mt-4">
                <div className="col-md-1 grey">Batches</div>
            </div>

            <div className="row">
                <div className="col-md-10">

                    <div className="row mt-2 mb-3">
                    <div className="col-md-12">
                        <input type="text" className="form-control" onChange={(e)=>{ setState({...state, search: e.target.value})}} placeholder="Search..." />
                    </div>
                    </div>
                    <DataTable
                    columns={[
                        { name: 'ID', selector: (row) => (<Link to={`/app/datasets/${id}/batchexec/${row.id}`}>{row.id}</Link>), },
                        { name: 'Name', selector: (row) => (row.name), sortable: true},
                        { name: 'State', selector: (row) => (row.state), sortable: true},
                        { name: 'Healthcheck', selector: row => <div> <Link to={`/app/datasets/${id}/batchexec/${row.id}/healthcheck`} className="greenanyverse">Run</Link> - <Link to={`/app/datasets/${id}/batchexec/${row.id}/viewhealthchecks/`} className="greenanyverse">View</Link> </div>},
                        { name: 'Creation', selector: row => (row.creation.slice(0,10)), sortable: true,}
                    ]}
                    data={filterData(state.data)}
                    />
                </div>

            </div>
        </div>
}

export default Dataset;