import ResourceGet from '../../resource/get';

const ScenesGet = () => {

      return(
        <div>
          <ResourceGet resource="scenes" name="Scenes" />
        </div>
      )

}

export default ScenesGet;