import { useEffect, useState, useContext } from "react";
import API from "../../../config/API";
import {Link} from 'react-router-dom';
import {EntornoGlobal} from '../../../config/globalContext';
import Loading from '../../theme/loading';

import './style.css';

const Pipe = () => {

    const [statecnx, setStateCnx] = useContext(EntornoGlobal);

    const [state, setState] = useState({
        resource_type: "loading",
        resource_list: [],
        seconds: 0
    });

    useEffect(async()=>{
        recover_all();
    },[]);

    useEffect(() => {
        const interval = setInterval(() => {

            setState(e => {
                if(e.seconds === 100){
                    refreshData(e.resource_type);
                }
                const sec = e.seconds+1;
                return {...e, seconds:sec}
            });
                

          }, 100);
          return () => clearInterval(interval);
      
      }, []);

    const callResource = async (resource="assets") => {
        const uri = '/v3/api/'+resource+'/query?'+(statecnx.user.isAdmin?"option=all&":"")+'metadata=true&ispublic=false&pending=true';
        const noReady = await API.post(uri);
        const Ready = await callResourceReady(resource);
        const resources = [].concat(noReady, Ready);
        return resources;
    }

    const callResourceReady = async (resource="assets") => {
        const uri = '/v3/api/'+resource+'/query?'+(statecnx.user.isAdmin?"option=all&":"")+'metadata=true&ispublic=false';
        const lasthour = new Date(new Date().getTime() - (1000*60*60));
        return await API.post(uri,{
            query: {
                lastUpdated: { "$gte": lasthour }
            }
        });
    }

    const recover_asset = async()=>{
        const list = (await callResource("assets")).map((e)=>{return {...e, type:"assets"}});
        changeState({...state, resource_list: list, resource_type: "assets" });
    }

    const recover_animations = async()=>{
        const list = (await callResource("animations")).map((e)=>{return {...e, type:"animations"}});
        changeState({...state, resource_list: list, resource_type: "characters" });
    }

    const recover_characters = async()=>{
        const list = (await callResource("characterAssets")).map((e)=>{return {...e, type:"characterAssets"}});
        changeState({...state, resource_list: list, resource_type: "characterAssets" });
    }

    const recover_materials = async()=>{
        const list = (await callResource("materials")).map((e)=>{return {...e, type:"materials"}});
        changeState({...state, resource_list: list, resource_type: "materials" });
    }

    const recover_scenes = async()=>{
        const list = (await callResource("scenes")).map((e)=>{return {...e, type:"scenes"}});
        changeState({...state, resource_list: list, resource_type: "scenes" });
    }

    const recover_backtrounds = async()=>{
        const list = (await callResource("backgrounds")).map((e)=>{return {...e, type:"backgrounds"}});
        changeState({...state, resource_list: list, resource_type: "backgrounds" });
    }


    const recover_all = async()=>{
        let list_assets = (await callResource("assets")).map((e)=>{return {...e, type:"assets"}});
        let list_animation = (await callResource("animations")).map((e)=>{return {...e, type:"animations"}});
        let list_characters = (await callResource("characterAssets")).map((e)=>{return {...e, type:"characterAssets"}});
        let list_scenes = (await callResource("scenes")).map((e)=>{return {...e, type:"scenes"}});
        let list_materials = (await callResource("materials")).map((e)=>{return {...e, type:"materials"}});
        let list_backgrounds = (await callResource("backgrounds")).map((e)=>{return {...e, type:"backgrounds"}});

        const list = [].concat(list_assets, list_characters, list_scenes, list_materials, list_animation, list_backgrounds);

        changeState({...state, resource_list: list, resource_type: "all" });
    }

    const filterByState = (filter) =>{
        return state.resource_list.filter( e => (e.state==filter && e.id.length>5) );
    };

    const changeState = (newState) => {
        newState.seconds = 0;
        setState(newState);
    }

    const refreshData = (type) => {
        if(type==="all"){recover_all()}
        if(type==="assets"){recover_asset()}
        if(type==="animations"){recover_animations()}
        if(type==="characterAssets"){recover_characters()}
        if(type==="materials"){recover_materials()}
        if(type==="backgrounds"){recover_backtrounds()}
        if(type==="scenes"){recover_scenes()}
    }

    const prepareData = (state) => {
        return filterByState(state).map((e)=>{
            return <div id={"resource"+e.id}><span className="greenanyverse smallinfo">{(e.type?e.type.toUpperCase()+" ":"")}</span>{ <Link to={"/app/"+e.type+"/get/"+e.id} title={e?.creation.slice(0,16).replace("T"," ")}>{e.name}</Link>}</div>;
        })
    }

    if(state.status === "loading"){ return <Loading /> }

    return(
        <div className="compPipeResource">
            <h2>Resource Pipe</h2>

            <div>
                <button className={
                    (state.resource_type==="assets"? "btn btn-anyverse btn-anyverse-active m-1" : "btn btn-anyverse m-1")
                } onClick={recover_asset}>Assets / Characters</button>
                <button className={
                    (state.resource_type==="characterAssets"? "btn btn-anyverse btn-anyverse-active m-1" : "btn btn-anyverse m-1")
                } onClick={recover_characters}>Characters</button>
                <button className={
                    (state.resource_type==="animations"? "btn btn-anyverse btn-anyverse-active m-1" : "btn btn-anyverse m-1")
                } onClick={recover_animations}>Animations</button>
                <button className={
                    (state.resource_type==="materials"? "btn btn-anyverse btn-anyverse-active m-1" : "btn btn-anyverse m-1")
                } onClick={recover_materials}>Materials</button>
                <button className={
                    (state.resource_type==="scenes"? "btn btn-anyverse btn-anyverse-active m-1" : "btn btn-anyverse m-1")
                } onClick={recover_scenes}>Scenes</button>
                <button className={
                    (state.resource_type==="backgrounds"? "btn btn-anyverse btn-anyverse-active m-1" : "btn btn-anyverse m-1")
                } onClick={recover_backtrounds}>Backgrounds</button>
                <button className={
                    (state.resource_type==="all"? "btn btn-anyverse btn-anyverse-active m-1" : "btn btn-anyverse m-1")
                } onClick={recover_all}>All</button>
            </div>
            <table className="table text-light mt-5">
                <thead>
                    <tr key="row0"> <td>Initial</td> <td>Topview</td> <td>Thumbnail</td> <td>Metadata</td> <td>Error</td> <td>Ready</td> </tr>
                </thead>
                <tbody>
                    <tr key="row1">
                        <td width={"16.6%"}> { prepareData(0) } </td>
                        <td width={"16.6%"}> { prepareData(3) } </td>
                        <td width={"16.6%"}> { prepareData(5) } </td>
                        <td width={"16.6%"}> { prepareData(4) } </td>
                        <td width={"16.6%"}> { prepareData(2) } </td>
                        <td width={"16.6%"}> { prepareData(1) } </td>
                    </tr>
                </tbody>
            </table>

                <p className="greenanyverse m-0">Next Refresh:</p>
                <div className="progress">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: (state.seconds)+"%"}}></div>
                </div>


        </div>
    );

};

export default Pipe;