import './styles.css';
import {useState, useEffect} from 'react';
import API from '../../config/API';
import {Link, useNavigate} from 'react-router-dom';
import Loading from '../../components/theme/loading';
import Header from '../../components/theme/header';

const Login = (props) =>{

  const [state, setState] = useState({status: "loading"});
  const navigate = useNavigate()

  useEffect(()=>{

    // Check if has token
      if(!!API.get_token()){

      // Check if the token is valid
        API.get('/v3/api/auth/me',{}).then((data)=>{

          if(typeof data !== "string"){
            navigate('/app');
          }else{
            setState({status:"loaded"});
          }
        });
      }else{
        setState({status:"loaded"});
      }

  },[]);

  const signin = (e) => {
    e.preventDefault();


    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    if(!email || !password){
      setState({status:true, message:"Please, enter email and password.", status:"danger"});
      return;
    }

    API.post('/v3/api/auth/signin',{ email: email, password: password }).then((data)=>{
      if(data?.token){
        API.save_token(data.token);
        navigate('/app');
      }else{
        setState({status:true, message:"Invalid email/password.", status:"danger"});
      }
    });

  }

  //if( state.status === "loading" ){ return <div><Loading></Loading></div>;}

	return (
    <div>
      <Header/>
      <div className="mt-5 comLogin">
        
        <div className="row">
          <div className='col-md-2'></div>
          <div className={"col-md-4 ms-2 "+(state.status === "loading"?"loading":"")}>
            <h4 className='mt-2 mb-5'>Sign in</h4>

            {(state?.message  ?
              <div className={"alert alert-"+state.status+" mt-3"}>{state.message}</div>
              :
              <></>
            )}

            {
              state.status === "loading" ?
                <div><div className='mt-5'></div><Loading></Loading></div>
              :
              <form onSubmit={signin}>
                <label>Email</label>
                <input type="email" id="email" name="email" className='form-control'></input>
                <br></br>
                <label>Password</label>
                <input type="password" id="password" name="password" className='form-control'></input>
                <br></br>
                <input type="submit" className='form-control btn btn-anyverse' value="SIGN IN" onClick={signin}></input>
                <div className='mt-2'><Link to="/recovery-password" className='pointer'>Forgot password?</Link></div>
              </form>
            }

          </div>
        </div>
      </div>
    </div>

	);
}

export default Login;