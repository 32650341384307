import { Fragment, useEffect, useState } from "react";
import {useNavigate, useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import API from '../../../config/API';
import {slugify} from '../../../config/funcaux';
import CodeEditor from '@uiw/react-textarea-code-editor';
import Loading from '../../theme/loading';

const EditTheme = () => {
  global.Buffer = global.Buffer || require('buffer').Buffer

  const nav = useNavigate();
  const [state, setState] = useState({ status : "loading", name: "" })

  const [code, setCode] = useState( "" );

  const {id} = useParams();

  useEffect(()=>{
    API.get('/v3/api/theme/'+id, {}).then((data)=>{
      if(data.status){
        setState({status:"loaded", name: data.data.name,});
        setCode(Buffer.from(data.data.content, 'base64').toString("utf8"));
      }else{
        setState({status:"error", message: "You don't have permissions"});
      }
    });
  },[]);


  const updateTheme = () => {
    const codeb64 = Buffer.from(code).toString('base64');
    const obj = {content: codeb64, name: state.name, type: "html"};

    API.put('/v3/api/theme/'+id, obj).then((data)=>{
      nav('/app/themes/');
    });
  }

  if(state.status === "loading"){ return <Loading /> }
  if(state.status !== "loaded"){ return <p>Loading...</p> }

  return(
    <div>
      <h2>Edit Theme</h2>
      
      <p className="mt-3">Name:</p>
      <input type="text" className="form-control" defaultValue={state.name} disabled></input>
      <p className="mb-4 p-2 fst-italic">{slugify(state.name)}</p>

      <p className="mt-3">Content:</p>
      <CodeEditor
      value={code}
      language="html"
      placeholder="Please enter HTML code."
      onChange={(evn) => setCode(evn.target.value)}
      padding={15}
      style={{
        fontSize: 14,
        backgroundColor: "#fff",
        fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
      }}
      />

      <br/>

      <button className="btn btn-success" onClick={updateTheme}>Update theme</button>

    </div>
  )

};

export default EditTheme;